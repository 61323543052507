import React from "react";
import {Adsense} from "@ctrl/react-adsense";
import StardeosAd from "@components/stardeos-ad/stardeos-ad.component";

export default function Ads({type = "horizontal"}: any) {
  const banner = (
    <div className="ads-banner" style={{display: 'none'}}>
      <div className="adsense-container">

        <div
          style={{width: 250, height: 360}}
        >

          <StardeosAd adType="video" position="initial" display="block" width="100%" height="100%" displayText={false}/>
        </div>
        {/* <div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            width: 26,
            padding: 3,
            background: "rgba(255,255,255,.95)",
            zIndex: 2,
          }}
        >
          <a href="http://agencyorquidea.com">
            <img
              src="https://supertruco.com/icon.svg"
              alt={"ad"}
              style={{
                display: "block",
                width: "100%",
                height: "auto",
              }}
            />
          </a>
        </div> */}
      </div>


      <style jsx global>{`
        .at-banner-area {
          background: transparent !important;
          box-shadow: none !important;

          .adsense-container {
            z-index: 5;
          }

          &.two-cols {
            grid-template-columns: auto 1fr !important;
            gap: 25px;

            @media screen and (max-width: 950px) {
              grid-template-columns: 1fr !important;
              padding: 15px 0px;

              .at-userbox {
                order: 2;
                flex-direction: row;
                width: 100%;
                min-height: auto;
                padding: 0px 20px;

                .at-contentbox {
                  margin-bottom: 0px;
                }

                .at-btnarea {
                  .button {
                    font-size: 10px !important;
                    padding: 0px 15px;
                  }
                }

                h3 {
                  margin-bottom: 0px;
                }

                .at-description {
                  display: none;
                }
              }
            }
          }

          .ads-banner {
            display: flex;
            width: 250px;
            height: 360px;
            /* background: linear-gradient(45deg, #bfaafd, #a6dffc); */
            color: white;
            border-radius: 8px;
            min-height: 300px;
            position: relative;
            align-items: center;
            justify-content: center;
            padding: 10px;

            @media screen and (max-width: 1550px) {
              display: none !important;
            }

            .stardust-ads-container {
              position: absolute;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              font-family: var(--primary-font);
              font-size: 14px;
              text-align: center;
              padding: 0px 10px;
            }
          }
        }
        .ad-container.open {
          background: linear-gradient(45deg, #FF5093, #9A37E7) !important;
          color: white !important;
        }
        
      `}</style>
    </div>
  );

  const horizontalBanner = (
    <div className="at-addcontent-hb" onClick={() => window.location.href = 'https://ads.stardeos.com/'}>
      <div className="stardust-ads-container">
        <div>
          ¡Anuncia <b>LO QUE QUIERAS</b>!
          <br/>A partir de 5€-10,000 visitas. ¡Precios locos de salida!
          <br/>👇 <b>CLIC AQUÍ</b> 👇
          <br/><u><b>http://ads.stardeos.com/</b></u>
        </div>
      </div>
      <div className="adsense-container">

        <div
          style={{width: 336, height: 280}}
        >

          <StardeosAd adType="video" position="initial" display="block" width="100%" height="100%" displayText={false}/>
        </div>
        {/*<div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            width: 26,
            padding: 3,
            background: "rgba(255,255,255,.95)",
            zIndex: 2,
          }}
        >
          <a href="http://agencyorquidea.com">
            <img
              src="https://supertruco.com/icon.svg"
              alt={"ad"}
              style={{
                display: "block",
                width: "100%",
                height: "auto",
              }}
            />
          </a>
        </div> */}
      </div>
      <style jsx>{`.ad-container.open {
          background: linear-gradient(45deg, #ad81ff, #8dd3ff) !important;
          color: white !important;
        }
        
        .at-addcontent-hb {
          background: linear-gradient(45deg, #FF5093, #9A37E7);
          color: white;
          border-radius: 8px;
          position: relative;
          width: 336px;
          height: 280px;
          max-height: 280px;
          align-self: center;
          margin-bottom: 20px;

          .stardust-ads-container {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 336px;
            height: 280px;
            display: flex;
            align-items: center;
            font-family: var(--primary-font);
            text-align: center;
            padding: 0px 10px;
            font-size: 16px;
          }
        }
      `}</style>
    </div>
  );

  const block = (
    <div className="at-addcontent" style={{cursor: "pointer"}}>
      <div className="stardust-ads-container" onClick={() => window.location.href = 'https://ads.stardeos.com/'}>
        <div>
          ¡Anuncia <b>LO QUE QUIERAS</b>!
          <br/>A partir de 5€-10,000 visitas. ¡Precios locos de salida!
          <br/>👇 <b>CLIC AQUÍ</b> 👇
          <br/><u><b>http://ads.stardeos.com/</b></u>
        </div>
      </div>
      <div className="adsense-container">
        <div
          style={{width: 300, height: 168}}
        >

          <StardeosAd adType="video" position="initial" display="block" width="100%" height="100%" displayText={false}/>
        </div>
        {/*<div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            width: 26,
            padding: 3,
            background: "rgba(255,255,255,.95)",
            zIndex: 2,
          }}
        >
          <a href="http://agencyorquidea.com">
            <img
              src="https://supertruco.com/icon.svg"
              alt={"ad"}
              style={{
                display: "block",
                width: "100%",
                height: "auto",
              }}
            />
          </a>
        </div> */}
        <div className="anuncio-label-div">
          <p className="anuncio-label">Anuncio</p>
        </div>
      </div>
      <style jsx>{`
      .anuncio-label-div {
          position: absolute;
          background-color: #ffe761;
          padding: 5px 19px;
          margin-top: 10px;
          border-radius: 17px;
      }
      .anuncio-label  {
        font-weight: 600;
        color: black;
      }
        .ad-container.open {
          background: linear-gradient(45deg, #ad81ff, #8dd3ff) !important;
          color: white !important;
        }
        .at-addcontent {
          color: #a795dd ;
          font-weight: 700;
          border: 3px solid #a6dffc;
          border-radius: 8px;
          position: relative;
          width: 300px;
          height: 168px;
          max-height: 250px;
          align-self: initial;

          .stardust-ads-container {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 300px;
            height: 168px;
            display: flex;
            align-items: center;
            font-family: var(--primary-font);
            text-align: center;
            padding: 0px 10px;
            font-size: 16px;
          }
        }
      `}</style>
    </div>
  );

  const horizontal = (
    <div className="ads-horizontal" onClick={() => window.location.href = 'https://ads.stardeos.com/'}>

      <div className="adsense-horizontal">
        <div>
          ¡Anuncia <b>LO QUE QUIERAS</b>!
          <br/>A partir de 5€-10,000 visitas. ¡Precios locos de salida!
          <br/>👇 <b>CLIC AQUÍ</b> 👇
          <br/><u><b>http://ads.stardeos.com/</b></u>
        </div>
        <div className="adsense-container">
          <Adsense
            client="ca-pub-1987169385649347"
            slot="6895924104"
            style={{width: 300, height: 100}}
            format=""
          />
        </div>

      </div>

      <style jsx>{`
        .ads-horizontal {
          display: none;
          
          @media screen and (max-width: 1200px){
            display: grid;
            width: 100%;
            height: 100px;
            margin-bottom: 20px;

            .adsense-horizontal {

              display: grid;
              position: absolute;
              justify-self: center;
              align-items: center;
              justify-items: center;
              width: 300px;
              height: 100px !important;
              background: linear-gradient(45deg, #FF5093, #9A37E7);
              border-radius: 8px;
              color: #fff;
              font-family: var(--primary-font);
              text-align: center;
              font-size: 16px;
              
              .adsense-container{
                cursor: pointer;
                width: 300px;
                height: 100px;
                position: absolute;

              }

            }

          }

        }

      `}</style>
    </div>
  );

  switch (type) {
    case "horizontal":
      return horizontal;
    case "block":
      return block;
    case "banner":
      return banner;
    case "horizontalBanner":
      return horizontalBanner;
    default:
      return null;
    // case "carousel":
    //   return carousel;
    // case "sidebar":
    //   return sidebar;
  }
}
