import React, { useState } from "react";
import Link from "next/link";
import { useAuth } from "@hooks/useAuth";
import { getIcon } from "@utils/getIcon.utils";

interface BtnProps {
  url?: string;
  name: string;
  star?: boolean;
  active?: boolean;
}

export const BtnComponent: React.FC<BtnProps> = ({ url, name, star, active }) => {
  const { user } = useAuth();
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
  };

  return (
    <div className={`at-btn ${ active ? clicked ? "clicked" : "" : ""}`}>
      <Link href={url ?  url : "#"}>
        <a style={{ display: "flex"}} onClick={handleClick}>
          {star ? (<i style={{ display: "flex"}}>
              <img src="/assets/images/star2.svg" alt="share" width="18" height="18"/>
            </i>) : ""}
          <span className="at-btn-val">
            {name}
          </span>
        </a>
      </Link>
      <style jsx>{`
        .at-btn {
          height: 40px;
          --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
            0 2px 4px -1px rgba(0, 0, 0, 0.06);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
            var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          padding: 10px 20px;
          border-radius: 20px !important;
          background-color: #1F243E;
          border: 1px solid #3A4065;

          i {
            margin-right: 5px;
          }

          &:hover, &.clicked {
            box-shadow: inset 1px 1px 1px 0px #ff5093, inset 0 0 0 2px #9A37e7;
          }

          .at-btn-val {
            font-weight: 700;
            font-size: 16px;
            color: #E7F3F3;
          }
        }
      `}</style>
    </div>
  );
};
