import css from 'styled-jsx/css'

export default css.global`
.css-g1d714-ValueContainer {
  padding: 2px 15px !important;
}
.at-videoslist {
    border-radius: 8px;
    grid-row-gap: 35px;

    @media screen and (max-width: 900px) {
      grid-row-gap: 20px;

      .streamer__details {
        display: none;
      }
    }
  }

  .at-video {
    border-radius: 8px;
  }

  .spinner-border {
    font-size: 25px !important;
    color: #c0e7fd !important;
  }

  .at-videobox {
    h4 {
      font-size: 12px;
      font-weight: 600;
      color: #2f3542;
      margin-bottom: 5px;
      font-family: var(--primary-font) !important;
    }

    .streamer {
      &__name {
        .text {
          color: #57606f;
          font-weight: 400;
          font-size: 1.4rem;
          font-family: var(--primary-font) !important;
        }
      }

      .at-viewsbox {
        color: #57606f;
        font-weight: 400;
        font-size: 1.4rem;
        font-family: var(--primary-font) !important;
      }
    }
  }

  .at-like {
    i {
      position: relative;
      top: 0px;
      margin-right: 10px !important;
      font-size: 15px;
    }

    span {
      line-height: 12px;
      font-family: var(--primary-font);
      font-size: 14px;
      font-weight: 500;
      -webkit-font-smoothing: antialiased;

      @media screen and (min-width: 1400px) {
        font-size: 14px;
      }
    }
  }

  .at-likeandrating-area {
    margin-bottom: 0px;
  }

  .at-dislike {
    i {
      position: relative;
      top: -1px;
      margin-right: 10px !important;

      @media screen and (min-width: 1400px) {
        font-size: 24px;
      }
    }

    span {
      line-height: 12px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 16px;
      font-weight: 500;
      -webkit-font-smoothing: antialiased;

      @media screen and (min-width: 1400px) {
        font-size: 18px;
      }
    }
  }

  .at-viewsbox {
    margin-bottom: 0px;
    position: relative;
    top: 5px;
  }

  .at-videorating {
    .imgIcon {
      top: 3px !important;
      margin-right: 3px !important;
      position: relative;

      @media screen and (min-width: 1400px) {
        top: 1px !important;
      }
    }

    span {
      line-height: 12px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 16px;
      font-weight: 500;
      -webkit-font-smoothing: antialiased;

      @media screen and (min-width: 1400px) {
        font-size: 18px;
      }
    }
  }

  .subscription-settings {
    .grid {
      display: grid;

      &.col-2 {
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }
    }

    .at-tablearea {
      @media screen and (max-width: 500px) {
        table {
          display: none;
        }
      }

      .no-mobile {
        display: hide;
        @media screen and (max-width: 500px) {
          display: block;
        }
      }

      .at-table th {
        color: #fff;
        font-weight: 500;
        font-family: var(--primary-font);
      }
    }

    .at-headcontent {
      padding: 20px 40px 15px;
    }
  }

  .at-commentsarea {
    padding: 0px 0px;

    .dropdown-menu {
      border-radius: 8px !important;

      .at-dropdownlist {
        display: flex;
        flex-direction: column;
        padding: 0px 0;

        .dropdown-item {
          font-family: var(--primary-font);
          font-size: 12px;
        }
      }
    }

    .at-addstardust {
      background: red;
      height: 40px;
      border-radius: 8px;
      text-shadow: 1px 1px 1px #8080803b;
      // background: linear-gradient(90deg, #d9cefe, #bfe6fd 50%);
      background-color: #1F243E;
      display: flex;
      align-items: center;

      a {
        position: absolute;
        right: 7px;
        background: #1F243E;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border-radius: 4px;
        font-size: 12px;
      }

      .at-addbtn {
        position: absolute;
        right: 7px;
        width: 26px;
        height: 26px;
        background: #1F243E;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border-radius: 4px;
        font-size: 12px;
      }

      .at-remaningcount {
        color: white !important;
        background: none !important;
        margin: 0px 10px;
        font-size: 15px;
        -webkit-font-smoothing: antialiased;
        position: relative;
        font-weight: 600;
      }

      input {
        background-color: #020410 !important;
        color: white;
        font-family: var(--primary-font);
        font-size: 14px;
        font-weight: 400;
        height: 40px !important;
        width: 180px;
        -webkit-box-shadow: inset 1px 1px 1px 0px #ff5093, inset 0 0 0 2px #9A37e7;
        box-shadow: inset 1px 1px 1px 0px #ff5093, inset 0 0 0 2px #9A37e7;

        &::placeholder {
          color: #a9aeb7 !important;
          font-family: var(--primary-font) !important;
        }
      }
    }

    .at-commentsendbox,
    .at-commentbox {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 20px;
      margin-bottom: 20px;
    }

    .letter-count {
      font-size: 12px;
      font-family: var(--primary-font);
    }

    .at-head {
      margin-bottom: 5px;

      @media screen and (max-width: 550px) {
        flex-direction: row;
        align-items: center;

        h3 {
          margin: 0px;
          height: 100%;
          top: 1px;
          position: relative;
          margin-right: 5px;
          font-size: 13px !important;
        }
      }

      h3 {
        text-transform: uppercase;
        font-family: var(--primary-font) !important;
        font-size: 15px;
        -webkit-font-smoothing: antialiased;
        font-weight: 600;
        letter-spacing: 0.2px;
      }

      button {
        .text {
          text-transform: uppercase;
          font-family: var(--primary-font) !important;
          font-size: 15px;
          -webkit-font-smoothing: antialiased;
          font-weight: 600;
          letter-spacing: 0.2px;

          @media screen and (max-width: 550px) {
            font-size: 13px;
          }
        }
      }
    }

    .at-themeimage {
      font-size: 15px;
      box-sizing: content-box;
    }

    .at-commentbox {
      .at-commentcontent {
        width: 100%;
        .at-commentsendbox {
          margin-top: 20px;
        }

        .at-title {
          h3 {
            font-family: var(--primary-font) !important;
            font-size: 14px;
            -webkit-font-smoothing: antialiased;
            letter-spacing: 0.2px;
          }

          .at-time {
            font-family: var(--primary-font) !important;
            font-size: 11px;
            color: #3A4065;
            -webkit-font-smoothing: antialiased;
          }
        }
        .at-description {
          border: none;

          * {
            font-family: var(--primary-font) !important;
            font-size: 13px;
            -webkit-font-smoothing: antialiased;
            font-weight: 500;
            line-height: 19px;
            white-space: pre-wrap;
            word-break: break-word;
            padding-right: 20px;
          }
        }
        .at-actionboxarea {
          .at-actionbox {
            button {
              span {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .at-themefrom {
      width: 100% !important;

      .at-commenttext {
        width: 100%;

        @media screen and (max-width: 550px) {
          margin: 0 0 5px !important;
        }

        textarea {
          width: 100%;
          font-family: var(--primary-font) !important;
          font-weight: 300;
          border-color: #383838;

          &::placeholder {
            color: #a9aeb7 !important;
            font-family: var(--primary-font) !important;
          }
        }
      }

      .at-commenttextaddstardust {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 25px;

        @media screen and (max-width: 550px) {
          grid-template-columns: 1fr;
          gap: 5px;

          input {
            width: 100%;
          }

          .at-btnarea {
            margin-top: 10px;
          }
        }
      }

      .at-btnarea {
        display: grid;
        grid-template-columns: auto auto;
        margin-top: 0px;
        gap: 15px;
        justify-content: flex-end;

        .cancel {
          margin-right: 0px;
        }

        button {
          font-family: var(--primary-font) !important;
          height: 40px;
          font-size: 14px;
          border-radius: 8px;
          padding: 0px 10px;
        }

        .button__secondary {
          background-color: hsl(211deg, 44%, 94%);
          color: hsl(211deg, 44%, 60%);
          cursor: pointer;
          transition: 0.5s background-color;

          &:hover {
            background-color: hsl(211deg, 44%, 92%);
          }
        }

        .button__primary {
          background-color: hsl(306deg, 100%, 96%);
          color: hsl(323deg, 100%, 77%);
          cursor: pointer;
          transition: 0.5s background-color;

          &:hover {
            background-color: hsl(306deg, 100%, 94%);
          }
        }
      }
    }
  }

  .at-banner .at-banner-area {
    position: relative;
    @media screen and (max-width: 600px) {
      padding: 15px 0px;
    }
  }

  .slider-btn {
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto auto;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #3A4065;
    color: white;
    border-radius: 50%;
    z-index: 5;
    cursor: pointer;

    &.left {
      left: 0px;
    }

    &.right {
      right: 0px;
    }

    svg {
      font-size: 20px;
    }
  }

  .swiper-slide {
    width: 90%;
    height: 300px;

    @media screen and (max-width: 1280px) {
      height: 285px;
    }
  }

  .swiper-wrapper {
    padding: 10px 0px;
  }

  .slide-container {
    @media screen and (max-width: 600px) {
      height: 180px !important;

      video {
        height: 100%;
      }
    }
  }

  video {
    height: 300px;
    object-fit: contain;
    background-color: #fcefff;
    margin: 0 auto;
    display: block;
    width: 100%;
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
    border-radius: 8px;
  }

  .mySwiper {
    max-width: 100%;
    margin: 0 auto;
    height: 300px;
    margin-bottom: 20px;

    @media screen and (max-width: 600px) {
      height: 215px !important;
    }

    @media screen and (max-width: 600px) {
      margin-bottom: 0px;
    }
  }

  .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-prev:after,
  .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
    background: linear-gradient(45deg, #fcd1f2, #61a5dc);
    color: white;
    padding: 10px 12px;
    border-radius: 5px;
    font-size: 18px;
  }

  .login-a {
    width: 40px;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }

  .image-nav {
    width: auto;
    height: 40px;
    position: relative;
  }

  .at-leftarea {
    display: flex;
  }

  .at-rightarea {
    gap: 10px;

    @media screen and (max-width: 650px) {
      display: none;
    }
  }

  .at-headerarea {
    padding: 15px 20px 0 25px;

    @media screen and (max-width: 580px) {
      flex-direction: row;
    }
  }

  .at-notificationdropdown {
    position: relative;
    
    .at-dropdowncontent {
      position: absolute;
      margin-top: 13px !important;
      margin: 0px;
      top: 50px !important;
      left: inherit !important;
      right: 3px !important;
      bottom: auto;
      transform: initial !important;
    }
  }

  .at-profiledropdown {
    position: relative;
    margin: 0px;

    .at-dropdowncontent {
      position: absolute;
      margin: 0px;
      top: 67px !important;
      left: inherit !important;
      right: 3px !important;
      bottom: auto;
      transform: initial !important;
    }
  }
  .at-rightarea {
    .button {
      &.primary {
        display: flex;
        align-items: center;
        padding: 0px 20px;
        font-size: 12px;
        height: 40px;
        border-radius: 8px !important;

        @media screen and (max-width: 640px) {
          max-width: 55px !important;
        }

        i {
          margin-right: 10px !important;

          @media screen and (max-width: 1110px) {
            margin-right: 0px !important;
          }
        }

        span {
          font-family: var(--primary-font);

          @media screen and (max-width: 1110px) {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .sdbr {
      display: none;
    }
  }

  @media screen and (max-width: 1040px) {
    .at-navarea {
    }

    .at-searchbox {
      display: none;
    }
  }

  .mobile-menu {
    display: none;

    @media screen and (max-width: 650px) {
      display: contents;
      margin-left: 15px;
    }
  }

`
